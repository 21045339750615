
.contact {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

ul {
    list-style-type: none;
}


ul li {
    color: black;
}
