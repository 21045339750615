
.lost-container {
    text-align: center;
    height: 100%;
}

.lost-container h1 {
    background-color: #2D72D9;
    color: #fff;
    margin: 0;
    font-size: 260px;
    font-weight: 300;
}

.lost-container p {
    background-color: #2D72D9;
}

.return {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    letter-spacing: -0.04em;
    margin: 0;
}
