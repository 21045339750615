
/* Miscellaneous Items */

.hvaglayout {
    padding-top: 80px;
}

.hvagbold {
    font-weight: bold
}

.hvag-header-color {
    color: orange;
}

span.complete{
    text-decoration: line-through
}
