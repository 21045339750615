
.caption {
    height: 500px;
    background-size: cover;
    background-image: url("https://mdbootstrap.com/img/Photos/Slides/img%20(4).jpg");
}

ul {
    color: white;
    list-style-type: none;
}

.community {
    background-color: #f0f0f0;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

hr {
    background-color: #bbb;
    border: none;
    height: 2px;
}

.h1c {
    color: white;
}
